import React from "react";
import HomePage from "../../components/HomePage";
import Layout from "../../components/Layout";
import MyHelmet from "../../context/MyHelmet";
import { graphql } from "gatsby";

export default ({ data: { wpPage } }) => {
  return (
    <div>
      <MyHelmet page="home" language="de" meta={wpPage.seo} />
      <Layout>
        <HomePage content={wpPage} />
      </Layout>
    </div>
  );
};

export const query = graphql`
  {
    wpPage(id: { eq: "cG9zdDoxNDgy" }) {
      content
      home {
        askForVizualizationButton
        fieldGroupName
        finalSectionButton
        finalSectionCopy1
        finalSectionFooter
        finalSectionTitle
        getInspiredTitle
        footerCopy2
        itsOnlyPackaging
        onlyPackagingCopy
        onlyPackagingMoreCopy
        orMaybeSomethingMore
        products {
          link
          text
        }
        secondSectionCopy
        secondSectionFooter
        secondSectionText1
        secondSectionTitle
        seeOfferButton
        seeCaseStudiesButton
        seeServicesButton
      }
      seo {
        metaDesc
        title
      }
    }
  }
`;
